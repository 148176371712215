import React from 'react'
import Contents03 from '../../../assets/images/Force_banner/Contents03.png'

function SwiperContents03() {
  return (
    <div>
      <img src={Contents03} alt="" />
    </div>
  )
}

export default SwiperContents03

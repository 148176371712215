import React from 'react'
import Contents05 from '../../../assets/images/Force_banner/Contents05.png'

function SwiperContents01() {
  return (
    <div>
      <img src={Contents05} alt="" />
    </div>
  )
}

export default SwiperContents01

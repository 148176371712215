// import logo from './logo.svg'
import "./App.css";
import Header from "./components/Header.tsx";
import Main from "./components/Main.tsx";
import Shooting from "./components/Shooting.tsx";
import Force from "./components/Force.tsx";
import Footer from "./components/Footer.tsx";
// import Talk from './components/Talk.tsx'
import Floating from "./components/Floating.tsx";
import Installation from "./components/Installation/Installation.tsx";
import Order from "./components/Order/Order.tsx";

import bg2 from "./assets/images/background/body_BG-2.png";
import bg3 from "./assets/images/background/body_BG-3.png";
import Partners from "./components/Partners/Partners.jsx";

const Background2 = () => {
  return (
    <img
      src={bg2}
      style={{
        zIndex: -1,
        position: "absolute",
        top: 1400,
        right: 0,
        width: "30vw",
      }}
    />
  );
};

const Background3 = () => {
  return (
    <img
      src={bg3}
      style={{
        zIndex: -1,
        position: "absolute",
        top: 3400,
        left: 0,
        width: "25vw",
      }}
    />
  );
};

function App() {
  return (
    <>
      <Header />
      <Floating />
      <Main />
      <Installation />
      <Partners />
      <Order />
      <Shooting />
      <Force />
      <Footer />
      <Background2 />
      <Background3 />
    </>
  );
}

export default App;

import React from "react";
import styles from "./ScrollLogoContainer.module.css";

const ScrollLogoContainer = ({ logos, direction }) => {
  return (
    <div className={styles.animationContainer}>
      <div className={`${styles.imageContainer} ${styles[direction]}`}>
        <div className={styles.imageChildContainer}>
          {logos.map((logo) => (
            <div className={styles.imageChild}>
              <img src={logo} alt="logo" />
            </div>
          ))}
        </div>
      </div>
      <div className={`${styles.imageContainer} ${styles[direction]}`}>
        <div className={styles.imageChildContainer}>
          {logos.map((logo) => (
            <div className={styles.imageChild}>
              <img src={logo} alt="logo" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScrollLogoContainer;

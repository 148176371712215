import React, { useEffect, useRef, useState } from "react";
import "../assets/css/Force.css";
import "../assets/css/Support.css";
import ForceContainer from "./Force/ForceContainer.tsx";
import SupportContainer from "./Force/SupportContainer.tsx";
import SupportContainer02 from "./Force/SupportContainer02.tsx";
import Section from "./common/Section.tsx";
import { useMediaQuery } from "react-responsive";
import styles from "./Force.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

const Price1 = ({ isMobile }) => {
  return (
    <div className={styles.price1}>
      <div className={isMobile ? styles.mobileGridRow : styles.gridRow}>
        <p className={styles.title}>선불 결제형</p>
        <p className={styles.title} style={{ flexGrow: 1 }}>
          <p className={styles.salesPrice}>
            <span style={{ textDecoration: "line-through" }}>월 17,000 원</span> (20% 할인)
          </p>
          → 월 <span className={styles.titlePrice}>14,000</span> 원
        </p>
        <p>태블릿 1대 당</p>
      </div>
      <div className={styles.divider} />
      <div className={isMobile ? styles.mobileGridRow : styles.gridRow}>
        <p className={styles.subTitle} style={{ flexGrow: 1 }}>
          태블릿 + 카드 리더기 + 고급 거치대
        </p>
        <p>부가세 별도</p>
      </div>
      <p className={`${styles.description} ${isMobile ? "text-center" : ""}`}>
        고객이 직접 주문, 결제 유도와 매출 상승이 필요한 업장에 추천합니다.
      </p>
    </div>
  );
};

const Price2 = ({ isMobile }) => (
  <div className={styles.price2}>
    <div className={isMobile ? styles.mobileGridRow : styles.gridRow}>
      <p className={styles.title}>
        후불 결제형
        {/* 월 <span className={styles.titlePrice}>14,000</span> 원 */}
      </p>
      <p className={styles.title} style={{ flexGrow: 1 }}>
        <p className={styles.salesPrice} style={{ color: "#aaa" }}>
          <span style={{ textDecoration: "line-through" }}>월 15,000 원</span> (20% 할인)
        </p>
        → 월 <span className={styles.titlePrice}>12,000</span> 원
      </p>
      <p className={styles.color222}>태블릿 1대 당</p>
    </div>
    <div className={styles.divider} />
    <div className={isMobile ? styles.mobileGridRow : styles.gridRow}>
      <p className={styles.subTitle} style={{ flexGrow: 1 }}>
        태블릿 + 고급 거치대
      </p>
      <p className={styles.color222}>부가세 별도</p>
    </div>
    <p className={`${styles.description} ${styles.color222} ${isMobile ? "text-center" : ""}`}>
      고객이 직접 주문, 결제가 많지 않은 업장에 적합니다.
    </p>
  </div>
);

const PricePlanSection = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" });
  const swiperRef = useRef(null);

  return (
    <Section title="가격 플랜">
      <div className={styles.container}>
        <div className={styles.box}>
          {!isTabletOrMobile ? (
            <div className={styles.gridContainer}>
              <div className={styles.picture}></div>
              <Price1 isMobile={false} />
              <Price2 isMobile={false} />
            </div>
          ) : (
            <Swiper
              pagination={true}
              modules={[Pagination]}
              className={styles.forceSwiper}
              onSwiper={(swiper) => (swiperRef.current = swiper)}
              // onSlideChange={handleSlideIndexChange}
              style={{ flexGrow: 1 }}
            >
              <SwiperSlide className={styles.selfCenter}>
                <Price1 isMobile={true} />
              </SwiperSlide>
              <SwiperSlide className={styles.selfCenter}>
                <Price2 isMobile={true} />
              </SwiperSlide>
            </Swiper>
          )}
          <ul className={styles.list}>
            <li>
              더오더 테이블의 선불형, 후불형 모두 약정기간이 종료되면 태블릿PC외 기타 장비의 소유권을 대표님이 가지게
              됩니다.
            </li>
            <li>더, 더, 더 주문하게 만드는 더오더 테이블은 월 사용료 이외 어떠한 비용도 청구하지 않습니다.</li>
            <li>
              24시간 철저한 유지보수, 그리고 본사에서 직접 설치 관리하며 온라인 원격지원 상시 가능한 유일한 테이블 오더
            </li>
            <li>더, 더, 더 주문하게 만드는 더오더 테이블</li>
          </ul>
        </div>
      </div>
    </Section>
  );
};

function Force() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="Force_wrap">
      <div>
        <div>
          <div className="Force_title">
            <p>THE order</p>
            <p>포스 시스템</p>
          </div>
          <ForceContainer />
        </div>

        <PricePlanSection />

        <div>
          <div className="Support_title">
            <p>THE order</p>
            <p>간편결제&배달플랫폼 지원</p>
            <span>
              일반 결제는 물론, 주요 간편결제 플랫폼과
              <br /> 배달 플랫폼 연동을 지원합니다
            </span>
          </div>
          {isMobile ? <SupportContainer02 /> : <SupportContainer />}
        </div>
      </div>
    </div>
  );
}

export default Force;

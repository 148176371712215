import React from 'react'
import Contents02 from '../../../assets/images/Force_banner/Contents02.png'

function SwiperContents02() {
  return (
    <div>
      <img src={Contents02} alt="" />
    </div>
  )
}

export default SwiperContents02

import React from "react";
import SystemBanner01 from "../../../assets/images/Systembanner/img_order_system_cart.png";
import "../../../assets/css/Order.css";
import { useMediaQuery } from "react-responsive";

function SystemContent01() {
  return (
    <div id="Content_Wrap">
      <div className="table_mockup">
        <img src={SystemBanner01} alt="" />
      </div>
      {/* <p>포스에 기반한 진짜 테이블 오더 시스템</p> */}
    </div>
  );
}

export default SystemContent01;

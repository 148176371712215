import React, { ReactNode, StyleHTMLAttributes } from "react";

import styles from "./Section.module.css";

interface props {
  title: string;
  subTitle?: ReactNode;
  className?: any;
  children: React.ReactNode;
}

const Section = ({ title, subTitle, children, className }: props) => {
  return (
    <section className={`${styles.container} ${className}`}>
      <p className={styles.commonTitle}>THE order</p>
      <h3 className={styles.title}>{title}</h3>
      {subTitle && <p className={styles.subTitle}>{subTitle}</p>}
      {children}
    </section>
  );
};

export default Section;

import React, { useEffect, useRef, useState } from "react";
import { Autoplay, EffectCoverflow, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./InstallationSwiper.module.css";

import CardImg01 from "./images/usage_01.jpg";
import CardImg02 from "./images/usage_02.jpg";
import CardImg03 from "./images/usage_03.jpg";
import CardImg04 from "./images/usage_04.jpg";
import CardImg05 from "./images/usage_05.jpg";
import CardImg06 from "./images/usage_06.jpg";
import CardImg07 from "./images/usage_07.jpg";
import CardImg08 from "./images/usage_08.jpg";
import CardImg09 from "./images/usage_09.jpg";
import CardImg10 from "./images/usage_10.jpg";
import CardImg11 from "./images/usage_11.jpg";
import CardImg12 from "./images/usage_12.jpg";
import CardImg13 from "./images/usage_13.jpg";
import CardImg14 from "./images/usage_14.jpg";
import CardImg15 from "./images/usage_15.jpg";
import CardImg16 from "./images/usage_16.jpg";
import CardImg17 from "./images/usage_17.jpg";
import CardImg18 from "./images/usage_18.jpg";
import CardImg19 from "./images/usage_19.jpg";
import CardImg20 from "./images/usage_20.jpg";
import InstallationSwiperCard from "./InstallationSwiperCard";
import { useMediaQuery } from "react-responsive";

const images = [
  CardImg01,
  CardImg02,
  CardImg03,
  CardImg04,
  CardImg05,
  CardImg06,
  CardImg07,
  CardImg08,
  CardImg09,
  CardImg10,
  CardImg11,
  CardImg12,
  CardImg13,
  CardImg14,
  CardImg15,
  CardImg16,
  CardImg17,
  CardImg18,
  CardImg19,
  CardImg20,
];

const InstallationSwiper = ({ refs }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 800px)" });
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef(null);

  const onPrevClick = (e) => {
    if (!swiperRef.current) return;
    swiperRef.current.slidePrev();
  };

  const onNextClick = (e) => {
    if (!swiperRef.current) return;
    swiperRef.current.slideNext();
  };

  useEffect(() => {
    const { prevRef, nextRef } = refs;
    if (refs && prevRef.current && nextRef.current) {
      prevRef.current.addEventListener("click", onPrevClick, false);
      nextRef.current.addEventListener("click", onNextClick, false);
    }
    return () => {
      prevRef.current.removeEventListener("click", onPrevClick, false);
      nextRef.current.removeEventListener("click", onNextClick, false);
    };
  }, [refs]);

  return (
    <Swiper
      effect="coverflow"
      grabCursor={true}
      centeredSlides={true}
      loop={true}
      slidesPerView="auto"
      onBeforeInit={(swiper) => {
        swiperRef.current = swiper;
      }}
      coverflowEffect={{
        rotate: 25,
        stretch: isTabletOrMobile ? 150 : 100,
        depth: 200,
        modifier: isTabletOrMobile ? 1 : 1.5,
        slideShadows: false,
      }}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      modules={[EffectCoverflow, Navigation, Pagination, Autoplay]}
      className={styles.swiper}
      onSlideChange={(s) => setActiveIndex(s.realIndex)}
      pagination={{
        el: `.${styles.swiperProgressBar}`,
        type: "progressbar",
      }}
    >
      <div className="swiper-wrapper">
        {images.map((image, index) => (
          <SwiperSlide key={image} className={styles["swiper-slide"]}>
            <InstallationSwiperCard
              src={image}
              isActive={activeIndex === index}
              isBothSide={
                activeIndex === index - 1 ||
                (activeIndex === 0 && index === images.length - 1) ||
                activeIndex === index + 1 ||
                (activeIndex === images.length - 1 && index === 1)
              }
              isBothEnd={
                activeIndex === index - 2 ||
                (activeIndex === 0 && index === images.length - 2) ||
                activeIndex === index + 2 ||
                (activeIndex === images.length - 2 && index === 1)
              }
            />
          </SwiperSlide>
        ))}
      </div>
      <div className={styles.swiperProgressBar}></div>
    </Swiper>
  );
};

export default InstallationSwiper;

import React, { useRef } from "react";
import "../../assets/css/Installation.css";
import Section from "../common/Section.tsx";
import InstallationSwiper from "./InstallationSwiper";
import styles from "./Installation.module.css";

import LeftArrow from "./images/icons/expand_left_light.png";
import RightArrow from "./images/icons/expand_right_light.png";

function Installation() {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <Section
      title="더오더로 간편한 설치"
      className="installation_section"
      subTitle={
        <p>
          더오더를 이용하시는 파트너를 위해
          <br /> 최고의 서비스를 제공하겠습니다.
        </p>
      }
    >
      <div className={`${styles["swiper-container"]}`}>
        <button className={styles.prev} ref={prevRef}>
          <img src={LeftArrow} alt="previous" width="32" height="32" />
        </button>
        <button className={styles.next} ref={nextRef}>
          <img src={RightArrow} alt="next" width="32" height="32" />
        </button>
        <InstallationSwiper refs={{ prevRef, nextRef }} />
      </div>
    </Section>
  );
}

export default Installation;

import React from "react";
import styles from "./Partners.module.css";

import Line1_01 from "./images/logos/line1/line1_01.png";
import Line1_02 from "./images/logos/line1/line1_02.png";
import Line1_03 from "./images/logos/line1/line1_03.png";
import Line1_04 from "./images/logos/line1/line1_04.png";
import Line1_05 from "./images/logos/line1/line1_05.png";
import Line1_06 from "./images/logos/line1/line1_06.png";
import Line1_07 from "./images/logos/line1/line1_07.png";
import Line1_08 from "./images/logos/line1/line1_08.png";
import Line1_09 from "./images/logos/line1/line1_09.png";
import Line1_10 from "./images/logos/line1/line1_10.png";
import Line1_11 from "./images/logos/line1/line1_11.png";
import Line1_12 from "./images/logos/line1/line1_12.png";
import Line1_13 from "./images/logos/line1/line1_13.png";
import Line1_14 from "./images/logos/line1/line1_14.png";
import Line1_15 from "./images/logos/line1/line1_15.png";
import Line1_16 from "./images/logos/line1/line1_16.png";
import Line1_17 from "./images/logos/line1/line1_17.png";
import Line1_18 from "./images/logos/line1/line1_18.png";
import Line1_19 from "./images/logos/line1/line1_19.png";
import Line1_20 from "./images/logos/line1/line1_20.jfif";
import Line1_21 from "./images/logos/line1/line1_21.png";
import Line1_22 from "./images/logos/line1/line1_22.png";

import Line2_01 from "./images/logos/line2/line2_01.png";
import Line2_02 from "./images/logos/line2/line2_02.png";
import Line2_03 from "./images/logos/line2/line2_03.png";
import Line2_04 from "./images/logos/line2/line2_04.png";
import Line2_05 from "./images/logos/line2/line2_05.png";
import Line2_06 from "./images/logos/line2/line2_06.png";
import Line2_07 from "./images/logos/line2/line2_07.png";
import Line2_08 from "./images/logos/line2/line2_08.png";
import Line2_09 from "./images/logos/line2/line2_09.png";
import Line2_10 from "./images/logos/line2/line2_10.png";
import Line2_11 from "./images/logos/line2/line2_11.png";
import Line2_12 from "./images/logos/line2/line2_12.png";
import Line2_13 from "./images/logos/line2/line2_13.png";
import Line2_14 from "./images/logos/line2/line2_14.png";
import Line2_15 from "./images/logos/line2/line2_15.png";
import Line2_16 from "./images/logos/line2/line2_16.png";
import Line2_17 from "./images/logos/line2/line2_17.png";
import Line2_18 from "./images/logos/line2/line2_18.png";
import Line2_19 from "./images/logos/line2/line2_19.png";
import Line2_20 from "./images/logos/line2/line2_20.png";
import Line2_21 from "./images/logos/line2/line2_21.png";

import Line3_01 from "./images/logos/line3/line3_01.png";
import Line3_02 from "./images/logos/line3/line3_02.png";
import Line3_03 from "./images/logos/line3/line3_03.png";
import Line3_04 from "./images/logos/line3/line3_04.png";
import Line3_05 from "./images/logos/line3/line3_05.png";
import Line3_06 from "./images/logos/line3/line3_06.png";
import Line3_07 from "./images/logos/line3/line3_07.png";
import Line3_08 from "./images/logos/line3/line3_08.png";
import Line3_09 from "./images/logos/line3/line3_09.png";
import Line3_10 from "./images/logos/line3/line3_10.png";
import Line3_11 from "./images/logos/line3/line3_11.png";
import Line3_12 from "./images/logos/line3/line3_12.png";
import Line3_13 from "./images/logos/line3/line3_13.png";
import Line3_14 from "./images/logos/line3/line3_14.png";
import Line3_15 from "./images/logos/line3/line3_15.png";
import Line3_16 from "./images/logos/line3/line3_16.png";
import Line3_17 from "./images/logos/line3/line3_17.png";
import Line3_18 from "./images/logos/line3/line3_18.png";
import Line3_19 from "./images/logos/line3/line3_19.png";
import Line3_20 from "./images/logos/line3/line3_20.png";
import ScrollLogoContainer from "./ScrollLogoContainer";

const line1Logos = [
  Line1_01,
  Line1_02,
  Line1_03,
  Line1_04,
  Line1_05,
  Line1_06,
  Line1_07,
  Line1_08,
  Line1_09,
  Line1_10,
  Line1_11,
  Line1_12,
  Line1_13,
  Line1_14,
  Line1_15,
  Line1_16,
  Line1_17,
  Line1_18,
  Line1_19,
  Line1_20,
  Line1_21,
  Line1_22,
];

const line2Logos = [
  Line2_01,
  Line2_02,
  Line2_03,
  Line2_04,
  Line2_05,
  Line2_06,
  Line2_07,
  Line2_08,
  Line2_09,
  Line2_10,
  Line2_11,
  Line2_12,
  Line2_13,
  Line2_14,
  Line2_15,
  Line2_16,
  Line2_17,
  Line2_18,
  Line2_19,
  Line2_20,
  Line2_21,
];

const line3Logos = [
  Line3_01,
  Line3_02,
  Line3_03,
  Line3_04,
  Line3_05,
  Line3_06,
  Line3_07,
  Line3_08,
  Line3_09,
  Line3_10,
  Line3_11,
  Line3_12,
  Line3_13,
  Line3_14,
  Line3_15,
  Line3_16,
  Line3_17,
  Line3_18,
  Line3_19,
  Line3_20,
];

const Partners = () => {
  return (
    <section className={styles.partnersSection}>
      <ScrollLogoContainer logos={line1Logos} direction="normal" />
      <ScrollLogoContainer logos={line2Logos} direction="reverse" />
      <ScrollLogoContainer logos={line3Logos} direction="normal" />
    </section>
  );
};

export default Partners;

import React from 'react'
import Contents04 from '../../../assets/images/Force_banner/Contents04.png'

function SwiperContents04() {
  return (
    <div>
      <img src={Contents04} alt="" />
    </div>
  )
}

export default SwiperContents04

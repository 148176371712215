import React from "react";
import styles from "./InstallationSwiperCard.module.css";

const InstallationSwiperCard = ({ src, isActive, isBothSide, isBothEnd }) => {
  return (
    <div
      className={`${styles.cardWrapper} ${isActive ? styles.active : ""} `}
      style={{
        background: `
        ${isBothSide ? "linear-gradient(89deg, rgba(228, 228, 228, 0.20) 1.16%, rgba(85, 85, 85, 0.60) 95%)," : ""} 
        ${isBothEnd ? "linear-gradient(89deg, rgba(228, 228, 228, 0.40) 1.16%, rgba(85, 85, 85, 0.80) 95%)," : ""}
        url(${src}) 0 0 / cover`,
      }}
    />
  );
};

export default InstallationSwiperCard;
